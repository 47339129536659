
import {f7} from "framework7-react";
import {mainModelBase} from "../../mc/MainModelBase";

export function showSimpleToast(text:string):void{
    console.dir(text);
    console.log('show toast text is', text);
    if(!mainModelBase?.f7IsReady)return;
    f7?.toast?.create({closeTimeout: 3000,text:text, destroyOnClose:true}).open();
}
export enum toastPositions{
    top = "top",
    center = "center",
    bottom = "bottom"
}
export function showToast(text:string, position?:toastPositions){
    position = position || toastPositions.bottom
    f7.toast.create({closeTimeout: 3000,text, destroyOnClose:true, position}).open();
}
